<template>
    <div>
        <p v-if="hintText">{{ $t(hintText) }}</p>
        <v-text-field
            :label="$t(placeholderText)"
            type="number"
            v-model.number="answer"
            :rules="isRequired ? [rules.required, rules.min, rules.max] : []"
            name="imo"
            counter="7"
            autocomplete="off"
            @blur="updateAnswer"
        ></v-text-field>
    </div>
</template>

<script>
import FieldTypes from '../../../mixins/FieldTypes';

export default {
    name: 'IMO',
    props: {
        hintText: {
            default: 'fields.imo.hint_text'
        },
        placeholderText: {
            default: 'fields.imo.placeholder'
        },
        isRequired: {
            default: true
        },
        questionId: {}
    },
    data() {
        return {
            answer: '',
            rules: {
                required: value => !!value || this.$t('fields.general.rules.required'),
                min: v => (v && v.toString().length > 6) || this.$t('fields.general.rules.exact_length', {length: 7}),
                max: v => (v && v.toString().length < 8) || this.$t('fields.general.rules.exact_length', {length: 7})
            }
        };
    },
    mixins: [FieldTypes]
};
</script>
